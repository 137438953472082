import React, {Fragment, useEffect} from 'react';
import agv from '@/assets/agv.png';
import { Grid, Image } from '@arco-design/web-react';
import {useRequest} from "ahooks";
import {fetchAgvStatus} from "@/pages/screen/service";
import styles from './index.module.less'

type ScreenAgvStatusItemProps = {
    agv: number,
    title: string
}
const ScreenAgvStatusItem = (props: ScreenAgvStatusItemProps) => {
    const {run,data} = useRequest((formParams)=>{
        return fetchAgvStatus(formParams);
    },{
        manual: true
    })

    useEffect(()=>{
        if(props.agv > 0) {
            setTimeout(()=>{
                run({agv: props.agv})
            }, 30000);
        }
    },[])

    return <Fragment>
        <Grid.Col span={8} style={{textAlign: "center"}}>
            <Image preview={false} src={agv}  alt={'运行中'}/>
            <h3 className={styles.title}>{props.title}</h3>
            {
                data && data.delivery_at!=null && data.arrival_at==null ?
                    <>
                        <p className={styles.status}>运行中</p>
                        <p className={styles.enstatus}>Running</p>
                    </>
                    :
                    <>
                        <p className={styles.status}>等待中</p>
                        <p className={styles.enstatus}>Waiting</p>
                    </>
            }

        </Grid.Col>
    </Fragment>
}

const ScreenAgvStatus = () =>{
    return <Fragment>
        <Grid.Row>
            <ScreenAgvStatusItem agv={10} title={'AGV 1'}/>
            <ScreenAgvStatusItem agv={11} title={'AGV 2'}/>
            <ScreenAgvStatusItem agv={12} title={'AGV 3'}/>
        </Grid.Row>
    </Fragment>
}

export default ScreenAgvStatus