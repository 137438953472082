import React, {useState} from 'react';
import {Tabs, Descriptions, Button} from '@arco-design/web-react';
import useUserStore from "@/store/user.store";

const Setting = () => {
    const userStore = useUserStore();
    const [activeTab, setActiveTab] = useState<string>('profile');

    return <Tabs activeTab={activeTab} onChange={setActiveTab}>
        <Tabs.TabPane key="profile" title={'安全设置'}>
            <Descriptions
                column={1}
                data={[
                    {
                        label: '姓名',
                        value: userStore.user?.idname
                    },
                    {
                        label: '用户名',
                        value: userStore.user?.username
                    },
                    {
                        label: '登陆密码',
                        value: <Button type={"text"}>修改</Button>
                    }
                ]}
                style={{ marginBottom: 20, marginLeft: 20 }}
                labelStyle={{ paddingRight: 36 }}
            />
        </Tabs.TabPane>
        <Tabs.TabPane key="action_log" title={'操作日志'}>
            操作日志
        </Tabs.TabPane>
    </Tabs>;
}

export default Setting;
