const i18n = {
  'en-US': {

  },
  'zh-CN': {
    'menu.dashboard': '主面板',
    'menu.dashboard.workplace': '个人工作区',


    'menu.call': '叫料管理',
    'menu.call.public': '公交梯',
    'menu.call.commercial': '商用梯',

    'menu.delivery': '送料管理',
    'menu.delivery.manual': '人工送料',
    'menu.delivery.agv': 'AGV送料',

    'menu.system': '系统设置',
    'menu.system.contract': '合同管理',
    'menu.system.role': '角色管理',
    'menu.system.permission': '权限管理',
    'menu.system.users': '用户管理',
    'menu.system.menu': '菜单管理',
    'menu.system.material': '物料管理',
    'menu.system.dictionary': '数据字典',
    'menu.system.truss_point': '桁架位/工位设置',
    'menu.system.line_group': '线组设置',
    'menu.system.setting': '参数设置',
    'menu.system.history': '操作日志',
    'menu.system.export': '数据报表',

    'navbar.logout': '注销登录',
    'navbar.setting':'用户设置',
    'logo-name': '送料管理系统',

    'message.tab.title.message': '消息通知',
    'message.allRead': '全部已读',
    'message.seeMore': '查看更多',
    'message.empty': '清空',
    'message.empty.tips': '暂无内容',
    'message.lang.tips': '语言切换至 ',
  },
};

export default i18n;

