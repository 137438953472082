import {
  Form,
  Input,
  Checkbox,
  Link,
  Button,
  Space,
  Message,
  Tabs
} from '@arco-design/web-react';
import { FormInstance } from '@arco-design/web-react/es/Form';
import { IconLock, IconUser } from '@arco-design/web-react/icon';
import React, { useRef, useState } from 'react';
import styles from '../style/index.module.less';
import { useRequest } from 'ahooks';
import { getUser, login } from '../service';
import { IdentifyProps } from '../model';
import { useNavigate } from 'react-router-dom';
import useLocale from '@/utils/useLocale';
import useUserStore from "@/store/user.store";
import locale from '../locale';
import {MenuProps} from "@/pages/system/menu/model";
import {fetchAllMenus} from "@/pages/system/menu/service";

const LoginForm = () => {
  const t = useLocale(locale);
  const formRef = useRef<FormInstance>();
  const [rememberPassword, setRememberPassword] = useState<boolean>(false)
  const navigate = useNavigate();
  const userStore = useUserStore()

  const { loading, run } = useRequest((values) => {
      return login({ ...values})
        .then(async (result: IdentifyProps) => { //IdentifyProps
          userStore.setIdentify(result);
          const data = await getUser()
          userStore.setUser(data);
          const menus: MenuProps[] = await fetchAllMenus({type: 'menu'})
          userStore.setMenus(menus);
          console.log(menus[0]?.route || menus[0]?.children[0]?.route)
          navigate('/')
        })
        .catch((error: any) => {
          Message.error(error?.data?.message || error?.data?.data?.message || t['login_error'])
        })
    },
    {
      throttleWait: 500,
      manual: true,
    })

  const onSubmitClick =  async () => {
    formRef.current.validate().then((values) => {
      run(values);
    });
  }

  return (
    <div className={styles['auth-form-wrapper']}>
      <div className={styles['auth-form-title']}>欢迎登录</div>
      <div className={styles['auth-form-sub-title']}>
        送料管理系统后台
      </div>
      <Form
        className={styles['auth-form']}
        layout="vertical"
        ref={formRef}
        initialValues={{ identify: '', password: '' }}
      >
        <Form.Item
            field="identify"
            rules={[{ message: t['username_empty_error']}]}
        >
          <Input
              prefix={<IconUser />}
              placeholder={'请输入用户名'}
          />
        </Form.Item>
        <Form.Item
            field="password"
            rules={[{ message: '密码不能为空' }]}
        >
          <Input.Password
              prefix={<IconLock />}
              placeholder={'请输入密码'}
          />
        </Form.Item>
        <Space size={16} direction="vertical">
          <div className={styles['auth-form-password-actions']}>
            <Checkbox checked={rememberPassword} onChange={setRememberPassword}>
              {'记住密码'}
            </Checkbox>
            <Link onClick={()=>navigate('../forgot-password')}>忘记密码</Link>
          </div>
          <Button type="primary" status={"success"} long onClick={onSubmitClick} loading={loading}>
            立即登录
          </Button>
        </Space>
      </Form>
    </div>
  );
}

export default LoginForm
