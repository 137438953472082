import React, { useMemo } from 'react';
import {Navigate, Route, Routes} from "react-router-dom";
import PrivateLayout from "@/layouts/private-layout";
import lazyload from '@/utils/lazyload';
import getFlattenRoutes from '@/utils/flattenRoutes';
import useRoute, {initRoutes} from '@/routes';
import useUserStore from '@/store/user.store';

const PrivatePage = () => {
    const { menus } = useUserStore();
    const [routes, defaultRoute] = useRoute(menus);
    const Exception403 = lazyload(() => import('../pages/exception/403'));
    const flattenRoutes = useMemo(() => getFlattenRoutes(routes) || [], [routes]);
    return  <Routes>
        <Route element={<PrivateLayout />}>
            {flattenRoutes.map((route, index) => {
                return (
                    <Route
                        key={index}
                        path={`${route.key}/*`}
                        element={<route.component />}
                    />
                );
            })}
            <Route path="/" element={<Navigate to={`/${defaultRoute}`}/>} />
            <Route path="*" element={<Exception403 />}
            />
        </Route>
    </Routes>
}

export default PrivatePage