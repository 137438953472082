import {Axis, Chart, Coordinate, Interaction, Interval, Tooltip } from 'bizcharts';
import React, {useState} from 'react';
import styles from "@/pages/screen/index.module.less";
import {Card, Grid, Tag} from '@arco-design/web-react';
import {useRequest} from "ahooks";
import {fetchDailyDelivery} from "@/pages/screen/service";
import {DailyDeliveryDataProps} from "@/pages/screen/model";
import _ from "lodash";


const ScreenLabelLine = () => {
    const [chartData, setChartData] = useState<DailyDeliveryDataProps[]>([])

    const { data } = useRequest(()=>{
        return fetchDailyDelivery({type: 'commercial'});
    }, {
        pollingInterval: 30000,
        onSuccess: (res => {
            setChartData([
                {
                    item: '按时送料',
                    count: res.ontime,
                    percent: _.round((res.ontime / res.total),2)
                },
                {
                    item: '提前送料',
                    count: res.advance,
                    percent: _.round((res.advance / res.total),2)
                },
                {
                    item: '延时送料',
                    count: res.delay,
                    percent: _.round((res.delay / res.total),2)
                }
            ])
        })
    })

    const cols = {
        percent: {
            formatter: val => {
                val = val * 100 + '%';
                return val;
            },
        },
    };

    return (
        <Card
            className={styles.card}
            style={{border: 0}}
            headerStyle={{marginTop: -10, padding: 0, height: 32}}
            bodyStyle={{padding: 0}}
            title={<h4 className={styles.title}>商用梯<small style={{marginLeft: 10}}>Commercial line</small></h4>}>
            <Chart height={200} data={chartData} scale={cols} autoFit>
                <Coordinate type="theta" radius={0.75} />
                <Tooltip showTitle={false} />
                <Axis visible={false} />
                <Interval
                    position="percent"
                    adjust="stack"
                    color="item"
                    style={{
                        lineWidth: 1,
                        stroke: '#fff',
                    }}
                    label={['count', {
                        // label 太长自动截断
                        layout: { type: 'limit-in-plot', cfg: { action: 'ellipsis' } },
                        content: (chartData) => {
                            return `${chartData.item}: ${chartData.percent * 100}%`;
                        },
                    }]}
                    state={{
                        selected: {
                            style: (t) => {
                                return { fill: 'red' }
                            }
                        }
                    }}
                />
                <Interaction type='element-single-selected' />
            </Chart>
            <Grid.Row>
                <Grid.Col span={12}>
                    <h4 style={{marginBottom: 0, marginTop: 0}} className={styles.title}>实时进度</h4>
                    <small style={{marginTop: 0}} >Realtime Process</small>
                </Grid.Col>
                <Grid.Col span={12}>
                    <Tag color={'#F65275'} style={{marginBottom: 5}}>
                        理论送料次数：{data?.call_times}
                    </Tag>
                    <Tag color={'#00C7B2'}>
                        送料次数：{data?.delivery_times}
                    </Tag>
                </Grid.Col>
            </Grid.Row>
        </Card>
    );
}

export default ScreenLabelLine

