export const deliveryStatusArr: API.EnumsProps[] = [
    { value: '1101', label: '未叫料' },
    { value: '1102', label: '未配送' },//修改已叫料为未配送，删除原未配送状态1103 于3/2,
    { value: '1104', label: '正在配送' },
    { value: '1105', label: '完成配送' },
]

export const callTypeArr: API.EnumsProps[] = [
    { value: '1001', label: '人工叫料'},
    { value: '1002', label: '自动叫料'},
]

export const elevatorTypeArr: API.EnumsProps[] = [
    { value: '1201', label: '商用梯'},
    { value: '1202', label: '公交梯'},
    { value: '1203', label: '人行梯'},
]

export const materialUnitArr: API.EnumsProps[] = [
    { value: '1301', label: '件'},
    { value: '1302', label: '台'},
    { value: '1303', label: '个'},
]

export const deliveryTypeArr: API.EnumsProps[] = [
    { value: '1601', label: 'AGV送料'},
    { value: '1602', label: '人工送料'},
]

export const statusArr: API.EnumsProps[] = [
    { value: '1701', label: '有效'},
    { value: '1702', label: '无效'},
]

export const booleanArr: API.EnumsProps[] = [
    { value: '1901', label: '是'},
    { value: '1902', label: '否'},
]

export const settingTypeArr: API.EnumsProps[] = [
    { value: 'text', label: 'text'},
    { value: 'json', label: 'json'},
]

export const arrivalStatusArr: API.EnumsProps[] = [
    { value: '2001', label: '提前达到'},
    { value: '2002', label: '准时达到'},
    { value: '2003', label: '延时达到'},
]