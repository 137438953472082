import {useMemo} from 'react';
import {MenuProps} from "@/pages/system/menu/model";

export type IRoute =  {
  name: string;
  key: string;
  children?: IRoute[];
};

export const initRoutes: MenuProps[] = [
  {
    name: 'menu.call',
    key: 'call',
    children: [
      {
        name: 'menu.call.public',
        key: 'call/communal',
      },
      {
        name: 'menu.call.commercial',
        key: 'call/commercial',
      },
    ]
  },
  {
    name: 'menu.system',
    key: 'system',
    children: [
      {
        name: 'menu.system.contract',
        key: 'system/contract',
      },
      {
        name: 'menu.system.role',
        key: 'system/role',
      },
      {
        name: 'menu.system.permission',
        key: 'system/permission',
      },
      {
        name: 'menu.system.menu',
        key: 'system/menu',
      },
      {
        name: 'menu.system.users',
        key: 'system/users',
      },
      {
        name: 'menu.system.material',
        key: 'system/material',
      },
      {
        name: 'menu.system.line_group',
        key: 'system/line_group',
      },
      {
        name: 'menu.system.truss_point',
        key: 'system/truss_point',
      },
      {
        name: 'menu.system.dictionary',
        key: 'system/dictionary',
      },
      {
        name: 'menu.system.history',
        key: 'system/history',
      },
      {
        name: 'menu.system.setting',
        key: 'system/setting',
      },
      {
        name: 'menu.system.export',
        key: 'system/export',
      },
    ]
  },
];

export const getName = (path: string, routes) => {
  return routes.find((item) => {
    const itemPath = `/${item.key}`;
    if (path === itemPath) {
      return item.name;
    } else if (item.children) {
      return getName(path, item.children);
    }
  });
};


const useRoute = (routes:MenuProps[]): [MenuProps[], string] => {
  const defaultRoute = useMemo(() => {
    return routes[0]?.children?.[0]?.key || routes[0]?.key;
  }, []);

  return [routes, defaultRoute];
};

export default useRoute;
