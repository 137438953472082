import defaultLocale from '../locale';
import useAppStore from "@/store/app.store";

const useLocale = (locale = null) => {
  const appStore = useAppStore();

  return (locale || defaultLocale)[appStore.lang] || {};
}

export default useLocale;
