import { request } from '@/utils/request';
import {MenuProps} from './model';

const menuInitial: MenuProps = {
    name: "",
    key: "",
    route: "",
    sort: 1,
}

/** 获取菜单列表 GET /v1/menus */
const fetchMenus = async (options?): Promise<MenuProps[]> => {
    return await request.get('/v1/menu', {
        params: {
            ...options
        }
    })
}

/**
 * 获取所有菜单
 */
const fetchAllMenus = async (options?): Promise<MenuProps[]> => {
    return await request.get('/v1/all-menu',{
        params: {
            ...options
        }
    })
}

/**
 * 更新菜单
 * @param body
 * @param options
 */
const updateMenu = async (body: MenuProps, options?) => {
    return await request.post(`/v1/menu/${options?.id}`, {
        ...body,
        ...options,
    }).then((result) => {
        return result;
    }).catch(error => error);
}

/**
 * 创建新菜单
 * @param body
 * @param options
 */
const createMenu = async (body: MenuProps, options?) => {
    return await request.post(`/v1/menu`, {
        ...body,
        ...options,
    }).then((result) => {
        return result;
    }).catch(error => error);
}

const removeMenu = async (id, options?) => {
    return await request.delete(`/v1/menu/${id}`, {
        ...options,
    }).then((result) => {
        return result;
    }).catch(error => error);
}

export {menuInitial, fetchMenus, fetchAllMenus, updateMenu, createMenu, removeMenu }