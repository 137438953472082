import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ConfigProvider } from '@arco-design/web-react';
import zhCN from '@arco-design/web-react/es/locale/zh-CN';
import enUS from '@arco-design/web-react/es/locale/en-US';
import { BrowserRouter, Routes, Route, Outlet} from 'react-router-dom';
import useAppStore from "@/store/app.store";
import useUserStore from '@/store/user.store';
import { getUser } from '@/pages/auth/service';
import { checkLogin } from '@/utils/authentication';
import AuthPage from "@/pages/auth-page";
import PrivatePage from "@/pages/private-page";
import './style/global.less';
import { startsWith } from 'lodash';
import Screen from "@/pages/screen";

const Index = () => {
    const getArcoLocale = () => {
        const lang = useAppStore.getState().lang;
        switch (lang) {
            case 'zh-CN':
                return zhCN;
            case 'en-US':
                return enUS;
            default:
                return zhCN;
        }
    }

    const fetchUserInfo = () => {
        getUser().then((res)=>{
            useUserStore.getState().setUser(res)
        }).catch(()=>{
            if (!startsWith(window.location.pathname.replace(/\//g, ''), 'auth')) {
                window.location.pathname = '/auth/login';
            }
        })
    }

    useEffect(() => {
        if (checkLogin()) {
           fetchUserInfo();
        } else if (!startsWith(window.location.pathname.replace(/\//g, ''),'auth')){
            window.location.pathname = '/auth/login';
        }
    }, []);

    return (
        <BrowserRouter>
            <ConfigProvider
                locale={getArcoLocale()}
                componentConfig={{
                    Card: {
                        bordered: false,
                    },
                    List: {
                        bordered: false,
                    },
                    Table: {
                        border: false,
                    },
                }}
            >
                <Routes>
                    <Route element={<Outlet />}>
                        <Route path="/auth/*" element={<AuthPage />} />
                        <Route path="/screen" element={<Screen />} />
                        <Route path="/*" element={<PrivatePage />} />
                    </Route>
                </Routes>
            </ConfigProvider>
        </BrowserRouter>
    );
}

const root = document.getElementById('root')!;
ReactDOM.createRoot(root).render(<Index />);