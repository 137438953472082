import React, { useState } from 'react';
import {
  Input,
  Avatar,
  Dropdown,
  Menu,
  Divider,
  Drawer,
} from '@arco-design/web-react';
import {
  IconNotification,
  IconSettings,
  IconPoweroff,
  IconDesktop,
  IconUser,
} from '@arco-design/web-react/icon';
import useLocale from '@/utils/useLocale';
import Logo from '@/assets/logo.svg';
import MessageBox from '@/components/MessageBox';
import IconButton from './IconButton';
import Settings from '../Settings';
import styles from './style/index.module.less';
import useUserStore from "@/store/user.store";
import {useNavigate} from "react-router";
import { logout as apiLogout } from '@/pages/auth/service'


const Navbar = () => {
  const userStore = useUserStore();
  const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);

  const t = useLocale();
  const navigate = useNavigate();
  const logout = () => {
    apiLogout().then(()=>{
      userStore.setUser(null);
      userStore.setIdentify(null);
      navigate('/auth');
    })
  }

  const goScreen = () => {
      navigate('/screen');
  }

  const onMenuItemClick = (key) => {
    if (key === 'logout') {
      logout();
    } else if(key ==='setting') {
      setVisibleDrawer(true)
    }
  }

  const droplist = (
    <Menu onClickMenuItem={onMenuItemClick}>
      <Menu.Item key="setting">
        <IconSettings className={styles['dropdown-icon']} />
          {t['navbar.setting']}
      </Menu.Item>
      <Divider style={{ margin: '4px 0' }} />
      <Menu.Item key="logout">
        <IconPoweroff className={styles['dropdown-icon']} />
        {t['navbar.logout']}
      </Menu.Item>
    </Menu>
  );

  return (
    <div className={styles.navbar}>
      <div className={styles.left}>
        <div className={styles.logo}>
          <Logo />
          <div className={styles['logo-name']}>{t['logo-name']}</div>
        </div>
      </div>
      <ul className={styles.right}>
        {/*<li>
          <Input.Search
            className={styles.round}
            placeholder={'输入内容查询'}
          />
        </li>*/}
          <li>
              <IconButton icon={<IconDesktop />} onClick={()=>goScreen()}/>
          </li>
        <li>
          <MessageBox>
            <IconButton icon={<IconNotification />} />
          </MessageBox>
        </li>
        {userStore.user && (
          <li>
            <Dropdown droplist={droplist} position="br">
              <Avatar size={32} style={{ cursor: 'pointer' }}>
                  <IconUser />
              </Avatar>
            </Dropdown>
          </li>
        )}
      </ul>
      <Drawer
          width={335}
          title={<>
            <Avatar size={32} style={{ cursor: 'pointer' }}>
                <IconUser />
            </Avatar>
              &nbsp;&nbsp;
            {userStore.user?.idname}
      </>}
          visible={visibleDrawer}
          onOk={() => {
            setVisibleDrawer(false);
          }}
          onCancel={() => {
            setVisibleDrawer(false);
          }}
      >
        <Settings />
      </Drawer>
    </div>
  );
}

export default Navbar;
