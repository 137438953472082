import React from 'react';
import {ConfigProvider, Table, TableColumnProps} from '@arco-design/web-react';
import './index.less'
import {useRequest} from "ahooks";
import {fetchScreenDeliveries} from "@/pages/screen/service";
import {deliveryStatusArr, deliveryTypeArr } from '@/enums';
import _ from "lodash";
import dayjs from "dayjs";

const columns: TableColumnProps[] = [
    {
        title: <>线组<p className={'subtr'}>Line</p></>,
        ellipsis: true,
        headerCellStyle: {width: '10%'},
        dataIndex: 'line_group',
    },
    {
        title: <>工位<p className={'subtr'}>Station</p></>,
        ellipsis: true,
        headerCellStyle: {width: '10%'},
        dataIndex: 'delivery_point',
    },
    {
        title: <>物料<p className={'subtr'}>Material</p></>,
        ellipsis: true,
        headerCellStyle: {width: '21%'},
        dataIndex: 'material_detail',
    },
    {
        title: <>计划配送时间<p className={'subtr'}>Planned delivery time</p></>,
        ellipsis: true,
        headerCellStyle: {width: '17%'},
        dataIndex: 'call_at',
        render: value => dayjs(value).format("MM-DD HH:mm")
    },
    {
        title: <>实际配送时间<p className={'subtr'}>Actual delivery time</p></>,
        ellipsis: true,
        headerCellStyle: {width: '17%'},
        dataIndex: 'delivery_at',
        render: value => value != null ? dayjs(value).format("MM-DD HH:mm") : ''
    },
    {
        title: <>状态<p className={'subtr'}>Status</p></>,
        ellipsis: true,
        headerCellStyle: {width: '12%'},
        dataIndex: 'delivery_status',
        render: (value) => _.find(deliveryStatusArr, { value: value?.toString() })?.label
    },
    {
        title: <>配送方式<p className={'subtr'}>Method</p></>,
        ellipsis: true,
        headerCellStyle: {width: '13%'},
        dataIndex: 'delivery_type',
        render: (value) => _.find(deliveryTypeArr, { value: value?.toString() })?.label
    },
];

const ScreenCommercialTable = () => {
    const { data } = useRequest(()=>{
        return fetchScreenDeliveries({type: 'commercial'});
    }, {
        pollingInterval: 30000,
    })

    return <ConfigProvider prefixCls={'custom-table arco'} componentConfig={{
        Table: {
            border: false,
        }
    }}>
        <Table rowKey={'id'} columns={columns} data={data} pagination={false} />
    </ConfigProvider>;
};

export default ScreenCommercialTable;