import React, {Fragment} from 'react';
import Empty from '@/assets/line/empty.svg';
import LineOne from '@/assets/line/line1.svg';
import LineTow from '@/assets/line/line2.svg';
import LineThree from '@/assets/line/line3.svg';
import LineFour from '@/assets/line/line4.svg';
import LineFive from '@/assets/line/line5.svg';
import Linesix from '@/assets/line/line6.svg';
import {Grid} from "@arco-design/web-react";
import styles from './index.module.less';
import {useRequest} from "ahooks";
import { fetchScreenContract } from '../../service';


const ScreenLineAdvance = () =>{
    const {data} = useRequest(()=>{
        return fetchScreenContract()
    },{
        pollingInterval: 30000
    })

    return <Fragment>
        {
            data && data.length > 0 ?
                data.map((item, index)=>{
                   return <Grid.Row key={index} style={{marginBottom: 20, marginTop: 20}}>
                       <Grid.Col span={8} style={{verticalAlign: "top"}}>
                           <>
                               <h5 className={styles.title}>线组编号</h5>
                               <p className={styles.content}>{item?.line_group_name}</p>
                               <h5 className={styles.title}>配送物料</h5>
                               <p className={styles.content}>{item?.material}({item?.material_detail})</p>
                           </>
                       </Grid.Col>
                       <Grid.Col span={16}>
                           {
                               item.line_group_id == 1 ? <LineOne />: ''
                           }
                           {
                               item.line_group_id == 2 ? <LineTow />: ''
                           }
                           {
                               item.line_group_id == 3 ? <LineThree />: ''
                           }
                           {
                               item.line_group_id == 4 ? <LineFour />: ''
                           }
                           {
                               item.line_group_id == 5 ? <LineFive />: ''
                           }
                           {
                               item.line_group_id == 6 ? <Linesix />: ''
                           }
                       </Grid.Col>
                   </Grid.Row>
                })
                : <><p className={styles.title}>没有线组运行</p><Empty /></>
        }
    </Fragment>
}

export default ScreenLineAdvance