import React, { useState, useMemo, useRef, useEffect} from 'react';
import {useNavigate, useLocation, Outlet} from 'react-router-dom';
import { Layout, Menu, Breadcrumb, Spin } from '@arco-design/web-react';
import cs from 'classnames';
import {
  IconDashboard,
  IconMenuFold,
  IconMenuUnfold,
  IconBook,
  IconSettings,

} from '@arco-design/web-react/icon';
import {
  IconOpportunity,
  IconDelivery
} from '@arco-iconbox/react-ruozhuo';
import qs from 'query-string';
import NProgress from 'nprogress';
import Navbar from '../components/NavBar';
import useRoute, {initRoutes, IRoute} from '@/routes';
import useLocale from '../utils/useLocale';
import getUrlParams from '../utils/getUrlParams';
import useAppStore from "@/store/app.store";
import useUserStore from "@/store/user.store";
import getFlattenRoutes from '@/utils/flattenRoutes';
import styles from '../style/layout.module.less';
import {MenuProps} from "@/pages/system/menu/model";


const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;

const Sider = Layout.Sider;
const Content = Layout.Content;

const getIconFromKey = (key) => {
  switch (key) {
    case 'dashboard':
      return <IconDashboard name={'dashboard'}  className={styles.icon} />;
    case 'contract':
      return <IconBook className={styles.icon} />
    case 'call':
      return <IconOpportunity className={styles.icon} />;
    case 'delivery':
      return <IconDelivery className={styles.icon} />;
    case 'system':
      return <IconSettings className={styles.icon} />;
    default:
      return <div className={styles['icon-empty']} />;
  }
}


const PrivateLayout = () => {
  const urlParams = getUrlParams();
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const currentComponent = qs.parseUrl(pathname).url.slice(1);
  const locale = useLocale();
  const { settings } = useAppStore();
  const { menus, userLoading } = useUserStore();

  const [routes, defaultRoute] = useRoute(menus);
  const defaultSelectedKeys = [currentComponent || defaultRoute];
  const paths = (currentComponent || defaultRoute)?.split('/');
  const defaultOpenKeys = paths?.slice(0, paths.length - 1);

  const [breadcrumb, setBreadCrumb] = useState([]);
  const [collapsed, setCollapsed] = useState<boolean>(false);
  const [selectedKeys, setSelectedKeys] =
      useState<string[]>(defaultSelectedKeys);
  const [openKeys, setOpenKeys] = useState<string[]>(defaultOpenKeys);

  const routeMap = useRef<Map<string, React.ReactNode[]>>(new Map());
  const menuMap = useRef<
      Map<string, { menuItem?: boolean; subMenu?: boolean }>
      >(new Map());

  const navbarHeight = 60;
  const menuWidth = collapsed ? 48 : settings.menuWidth;

  const showNavbar = settings.navbar && urlParams.navbar !== false;
  const showMenu = settings.menu && urlParams.menu !== false;

  const flattenRoutes = useMemo(() => getFlattenRoutes(routes) || [], [routes]);

  const onClickMenuItem = (key) => {
    const currentRoute = flattenRoutes.find((r) => r.key === key);
    const component = currentRoute.component;
    const preload = component.preload();
    NProgress.start();
    preload.then(() => {
      navigate(currentRoute.path ? currentRoute.path : `/${key}`);
      NProgress.done();
    });
  }

  const toggleCollapse = () => {
    setCollapsed((collapsed) => !collapsed);
  }

  const paddingLeft = showMenu ? { paddingLeft: menuWidth } : {};
  const paddingTop = showNavbar ? { paddingTop: navbarHeight } : {};
  const paddingStyle = { ...paddingLeft, ...paddingTop };

  const renderRoutes = (locale) => {
    routeMap.current.clear();
    return function travel(_routes: MenuProps[], level, parentNode = []) {
      return _routes.map((route) => {
        const { breadcrumb = true, ignore } = route;
        const iconDom = getIconFromKey(route.key);
        const titleDom = (
            <>
              {iconDom} {locale[route.name] || route.name}
            </>
        );

        routeMap.current.set(
            `/${route.key}`,
            breadcrumb ? [...parentNode, route.name] : []
        );

        const visibleChildren = (route.children || []).filter((child) => {
          const { ignore, breadcrumb = true } = child;
          if (ignore || route.ignore) {
            routeMap.current.set(
                `/${child.key}`,
                breadcrumb ? [...parentNode, route.name, child.name] : []
            );
          }

          return !ignore;
        });

        if (ignore) {
          return '';
        }
        if (visibleChildren.length) {
          menuMap.current.set(route.key, { subMenu: true });
          return (
              <SubMenu key={route.key} title={titleDom}>
                {travel(visibleChildren, level + 1, [...parentNode, route.name])}
              </SubMenu>
          );
        }
        menuMap.current.set(route.key, { menuItem: true });
        return <MenuItem key={route.key}>{titleDom}</MenuItem>;
      });
    };
  }

  const updateMenuStatus = () => {
    const pathKeys = pathname.split('/');
    const newSelectedKeys: string[] = [];
    const newOpenKeys: string[] = [...openKeys];
    while (pathKeys.length > 0) {
      const currentRouteKey = pathKeys.join('/');
      const menuKey = currentRouteKey.replace(/^\//, '');
      const menuType = menuMap.current.get(menuKey);
      if (menuType && menuType.menuItem) {
        newSelectedKeys.push(menuKey);
      }
      if (menuType && menuType.subMenu && !openKeys.includes(menuKey)) {
        newOpenKeys.push(menuKey);
      }
      pathKeys.pop();
    }
    setSelectedKeys(newSelectedKeys);
    setOpenKeys(newOpenKeys);
  }

  useEffect(() => {
    const routeConfig = routeMap.current.get(pathname);
    setBreadCrumb(routeConfig || []);
    updateMenuStatus();
  }, [pathname]);

  return (
      <Layout className={styles.layout}>
        <div
            className={cs(styles['layout-navbar'], {
              [styles['layout-navbar-hidden']]: !showNavbar,
            })}
        >
          <Navbar/>
        </div>
        {userLoading ? (
            <Spin className={styles['spin']} />
        ) : (
            <Layout>
              {showMenu && (
                  <Sider
                      className={styles['layout-sider']}
                      width={menuWidth}
                      collapsed={collapsed}
                      onCollapse={setCollapsed}
                      trigger={null}
                      collapsible
                      breakpoint="xl"
                      style={paddingTop}
                  >
                    <div className={styles['menu-wrapper']}>
                      <Menu
                          collapse={collapsed}
                          onClickMenuItem={onClickMenuItem}
                          selectedKeys={selectedKeys}
                          openKeys={openKeys}
                          onClickSubMenu={(_, openKeys) => {
                            setOpenKeys(openKeys);
                          }}
                      >
                        {renderRoutes(locale)(routes, 1)}
                      </Menu>
                    </div>
                    <div className={styles['collapse-btn']} onClick={toggleCollapse}>
                      {collapsed ? <IconMenuUnfold /> : <IconMenuFold />}
                    </div>
                  </Sider>
              )}
              <Layout className={styles['layout-content']} style={paddingStyle}>
                <div className={styles['layout-content-wrapper']}>
                  {!!breadcrumb.length && (
                      <div className={styles['layout-breadcrumb']}>
                        <Breadcrumb>
                          {breadcrumb.map((node, index) => (
                              <Breadcrumb.Item key={index}>
                                {typeof node === 'string' ? locale[node] || node : node}
                              </Breadcrumb.Item>
                          ))}
                        </Breadcrumb>
                      </div>
                  )}
                  <Content style={{height: 'calc(100% - 40px'}}>
                    <Outlet />
                  </Content>
                </div>
              </Layout>
            </Layout>
        )}
      </Layout>
  );
}

export default PrivateLayout;
