import React, {Fragment, useEffect, useState} from 'react';
import styles from './style/index.module.less';
import {Badge, Button, Card, List, Result, Tooltip, Trigger} from '@arco-design/web-react';
import {useRequest} from "ahooks";
import {fetchAllMarkReadNotification, fetchMarkReadNotification, fetchNotifications} from "@/components/MessageBox/service";
import useLocale from '@/utils/useLocale';
import {MessageProps, MessagesProps} from "@/components/MessageBox/model";
import useUserStore from "@/store/user.store";

interface PopupContentProps {
    handleMarkReadAll: any
    handleItemClick: any
    run: any
    loading: boolean
    data: MessagesProps
}

const PopupContent = (props: PopupContentProps)=> {
    const t = useLocale();
    const [data, setData] = useState<MessageProps[]>([])

    useEffect(()=>{
        setData(props?.data?.list);
    },[props])

    const handleReachBottom = (currentPage: number) => {
        if( props.data?.pagination?.total_pages > currentPage) {
            props.run(currentPage);
        }
    }

    const handleMarkReadAll = () => {
        props.handleMarkReadAll();
    }

    const handleItemClick = (item: MessageProps) => {
        props.handleItemClick(item);
    }

    return (
        <div className={styles['message-box']}>
            <Card bodyStyle={{padding: 0}}
                  title={<strong>{t['message.tab.title.message']}</strong>}
                  extra={<Button onClick={()=>handleMarkReadAll()} status={"default"} size={"small"}>列表已读</Button>}>
                <List
                    style={{ width: '100%', maxHeight: 320 }}
                    noDataElement={<Result status="404" subTitle={t['message.empty.tips']} />}
                    dataSource={data}
                    onReachBottom={(currentPage) => {handleReachBottom}}
                    render={(item, index) => (
                        <List.Item key={index} extra={<Button onClick={()=>handleItemClick(item)} size={"mini"} status={"danger"}>标记已读</Button>}>
                            <List.Item.Meta
                                title={<>{item.read_at == null ? <Badge color={'red'} /> : ''}<span className={styles['message-title']}>{item.data.title}</span></>}
                                description={<span className={styles['message-content']}>{item.data.message}</span>}
                            />
                            <span className={styles['message-time']}>{item?.created_at}</span>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    );
}

const MessageBox = ({ children }) => {
    const userStore = useUserStore();
    const { loading, run, refresh, data } = useRequest((current)=>{
        return fetchNotifications({current,pageSize: 10});
    }, {
        manual: true
    })

    useEffect(()=>{
        if(userStore?.user?.id) {
            setTimeout(function() {
                run(0);
            },10000);
        }
    },[run])

    const handleItemClick = (item: MessageProps) => {
        fetchMarkReadNotification(item.id).then((res)=>{
            refresh();
        })
    }

    const handleMarkReadAll =() => {
        fetchAllMarkReadNotification().then((res)=>{
            refresh();
        })
    }

    return (
        <Fragment>
            <Trigger
                unmountOnExit={false}
                popupAlign={{ bottom: 4 }}
                popup={() =><PopupContent
                    data={data}
                    run={run}
                    loading={loading}
                    handleItemClick={handleItemClick}
                    handleMarkReadAll={handleMarkReadAll}/>}
                trigger='click'
                position='br' classNames='zoomInTop'>
                <Badge count={data?.pagination?.total}>
                    {children}
                </Badge>
            </Trigger>
        </Fragment>
    );
}

export default MessageBox;
