import { create } from 'zustand'
import {IdentifyProps, UserProps} from "@/pages/auth/model";
import {persist} from "zustand/middleware";
import {StorageKey} from "@/constants";
import {MenuProps} from "@/pages/system/menu/model";

interface userStore {
    user: UserProps | null;
    menus: MenuProps[] | null;
    setMenus: (menus: MenuProps[]) => void;
    setUser: (user: UserProps) => void;
    userLoading?: boolean;
    setUserLoading: (userLoading: boolean) => void;
    identify: IdentifyProps;
    setIdentify: (identify: IdentifyProps) => void;
}

const useUserStore = create<userStore>()(
    persist((set,get) => ({
        user: null,
        menus: [],
        setUser: (user) => set(() => ({ user })),
        setMenus: (menus) => set(()=>({menus})),
        userLoading: false,
        setUserLoading: (userLoading => set(()=>({userLoading}))),
        identify: null,
        setIdentify: (identify) => set(() => ({ identify })),
    }),
        { name: StorageKey.USER_KEY })
)

export default useUserStore