import axios from 'axios'
import {checkLogin} from "@/utils/authentication";
import useUserStore from "@/store/user.store";


const request = axios.create({
    baseURL: 'https://otis.51wiseiot.com/api', //'', //http://10.69.113.250/api ////process.env.APP_API_URL, // API 请求的默认前缀'http://buymiao.sophlive/api/v1', //
    timeout: 6000, // 请求超时时间
})

request.interceptors.request.use(
    (config) => {
        if (checkLogin()) {
            const userStore = useUserStore.getState();
            config.headers = {
                ...config.headers,
                'Access-Control-Allow-Origin': '*',
                Authorization: `${userStore.identify.token_type} ${userStore.identify.token}`,
            }
        }
        return config
    },
    (error) => console.error(error)
)

request.interceptors.response.use(
    (response) => {
        if (response.status == 200) {
            return Promise.resolve(response.data?.data);
        }
    },
    (error)=>{
        return Promise.reject(error.response)
        /*if (error.response.status) {
            switch (error.response.status) {
                /!*case 401:
                    redirect('/auth/login')
                    break
                case 403:
                    redirect('/exception/403')
                    break
                case 404:
                    redirect('/exception/404')
                    break*!/
                default:
                    throw error //return Promise.reject(error.response?.data)
            }
        }*/
    })

export { request }
