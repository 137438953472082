import {Chart, Interval, PieChart, Tooltip} from "bizcharts";
import React, {useState} from "react";
import styles from "@/pages/screen/index.module.less";
import { Card } from "@arco-design/web-react";
import {useRequest} from "ahooks";
import {fetchSevenDailyDelivery} from "../../service";

const ScreenChart = () => {

    const {data} = useRequest(()=>{
        return fetchSevenDailyDelivery();
    }, {
        pollingInterval: 30000,
    })

    const cols = {
        times: {
            min: 0,
            alias: '配送次数',
            type: 'linear-strict'
        },
    };

    return <>
        <Card
            className={styles.card}
            style={{border: 0}}
            headerStyle={{padding: 0, marginBottom: 0, height: 32}}
            bodyStyle={{padding: 0, marginBottom: 0, marginTop: 0}}>
            <Chart height={230} scale={cols} autoFit data={data} >
                <Interval position="delivery_at*times" style={{ lineWidth: 2 }} />
                <Tooltip shared />
            </Chart>
        </Card>
    </>
}

export default ScreenChart