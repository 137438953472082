import lazyload from "./lazyload";
import { isArray } from "lodash";

const getFlattenRoutes = (routes) => {
    const mod = import.meta.glob('/src/pages/**/[a-z[]*.tsx');
    const res = [];
    const travel = (_routes) => {
        _routes.forEach((route) => {
            if (route.key && !route.children) {
                route.component = lazyload(mod[`/src/pages/${route.key}/index.tsx`]);
                res.push(route);
            } else if (isArray(route.children) && route.children.length) {
                travel(route.children);
            }
        });
    }
    travel(routes);
    return res;
}


export default getFlattenRoutes