import React, { useEffect } from 'react';
import Footer from '@/components/Footer';
import Logo from '@/assets/logo.svg';
import styles from '../style/layout.module.less';
import {Carousel} from "@arco-design/web-react";
import { Outlet } from 'react-router-dom';
import authPng  from '@/assets/auth.png'

const AuthLayout = () => {
    useEffect(() => {
        document.body.setAttribute('arco-theme', 'light');
    }, []);

    const data = [
        {
            slogan: '管理创造效益',
            subSlogan: '成本管控,提高项目效益,创造更大经济效益',
            image: authPng,
        },
        {
            slogan: '始于细微,见于平凡',
            subSlogan: '行动始于细微 服务见于平凡',
            image: authPng,
        },
        {
            slogan: '科学技术是第一生产力',
            subSlogan: '科技引领未来,创新驱动发展',
            image: authPng,
        },
    ];

    return (
        <div className={styles.container}>
            <div className={styles.logo}>
                <Logo />
                <div className={styles['logo-text']}>送料管理系统</div>
            </div>
            <div className={styles.banner}>
                <div className={styles['banner-inner']}>
                    <Carousel className={styles.carousel} animation="fade">
                        {data.map((item, index) => (
                            <div key={`${index}`}>
                                <div className={styles['carousel-item']}>
                                    <div className={styles['carousel-title']}>{item.slogan}</div>
                                    <div className={styles['carousel-sub-title']}>{item.subSlogan}</div>
                                    <img
                                        alt="banner-image"
                                        className={styles['carousel-image']}
                                        src={item.image}
                                    />
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
            <div className={styles.content}>
                <Outlet />
                <div className={styles.footer}>
                    <Footer />
                </div>
            </div>
        </div>
    );
}

export default AuthLayout;
