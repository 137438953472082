import {MessagesProps} from "@/components/MessageBox/model";
import { request } from "@/utils/request";

export async function fetchNotifications({ current, pageSize}: any, options?): Promise<MessagesProps> {
    return request.get('/v1/notification/list', {
        params: {
            page: current,
            pageSize,
            ...options,
        },
    })
}

export async function fetchNotificationCount(options?: {
    [p: string]: any
}): Promise<{count: number}> {
    return request.get('/v1/notification/count', { ...options })
}

export async function fetchMarkReadNotification(id, options?): Promise<void> {
    return request.get(`/v1/notification/${id}/mark`, { ...options })
}

export async function fetchAllMarkReadNotification(options?): Promise<void> {
    return request.get(`/v1/notification/clear-notifications`, { ...options })
}