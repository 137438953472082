import { request } from '@/utils/request';
import { IdentifyProps, LoginProps, UserProps } from './model';

export async function login(
  body: LoginProps,
  options?: { [p: string]: any }
): Promise<IdentifyProps> {
  return request.post('/v1/login', body, { ...options })
}

export async function logout(): Promise<any> {
  return request.get('/v1/logout')
}

export function getUser(): Promise<UserProps> {
  return request.get('/v1/me')
}
