import React from "react";
import FullScreenContainer from "@/components/FullScreenContainer";
import {Card, Grid, Layout, Tag} from "@arco-design/web-react";
import styles from './index.module.less'
import ScreenCommercialTable from "@/pages/screen/components/ScreenCommercialTable";
import ScreenCommunalTable from "@/pages/screen/components/ScreenCommnualTable";
import ScreenChart from "@/pages/screen/components/ScreenChart";
import ScreenLineAdvance from "@/pages/screen/components/ScreenLineAdvance";
import ScreenCommercialLabelLine from "@/pages/screen/components/ScreenCommercialLabelLine";
import Footer from "@/components/Footer";
import ScreenAgvStatus from "./components/ScreenAgvStatus";
import ScreenAgvOee from "./components/ScreenAgvOee";
import packageJson from "../../../package.json";
import ScreenCommnualLabelLine from "./components/ScreenCommnualLabelLine";


const Screen = () => {
    const Row = Grid.Row
    const Col = Grid.Col
    const Header = Layout.Header
    const Footer = Layout.Footer
    const Content = Layout.Content

    return (<FullScreenContainer style={{ backgroundColor: '#061436', overflowX: 'hidden' }}>
        <Layout>
            <Header className={styles.header}>
                <h3 className={styles.maintitle}>送料看板</h3>
                <h5 className={styles.subtitle}>Material Feeding Board</h5>
            </Header>
            <Content>
                <Row gutter={24}>
                    <Col span={6}>
                        <Card className={styles.card}
                              bodyStyle={{paddingBottom: 0}}
                              title={<h3 className={styles.title}>当天配送情况<small style={{marginLeft: 10}}>Daily delivery</small></h3>}>
                            <ScreenCommercialLabelLine />
                            <ScreenCommnualLabelLine />
                        </Card>
                        <Card className={styles.card}
                              bodyStyle={{paddingBottom: 0, marginBottom:0}}
                              title={<h3 className={styles.title}>近7天配送情况<small style={{marginLeft: 10}}>Trend of delivery</small></h3>}>
                            <ScreenChart/>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Card className={styles.card}
                              bodyStyle={{height: 0, padding: 0}}
                              title={<h3 className={styles.title}>实时配送状态<small style={{marginLeft: 10}}>Real-time delivery status</small></h3>} />
                        <Card
                            className={styles.card}
                            bodyStyle={{padding: 5}}
                            title={<h4 className={styles.title}>商用梯<small style={{marginLeft: 10}}>Commercial line</small></h4>}>
                            <ScreenCommercialTable />
                        </Card>
                        <Card className={styles.card}
                              bodyStyle={{padding: 5}}
                              title={<h4 className={styles.title}>公交梯<small style={{marginLeft: 10}}>Public line</small></h4>}>
                            <ScreenCommunalTable />
                        </Card>
                    </Col>
                    <Col span={6}>
                        <Card
                            className={styles.card}
                            headerStyle={{marginTop: 0}}
                            bodyStyle={{paddingTop: 0, paddingLeft: 0, paddingBottom: 0}}
                            title={<h3 className={styles.title}>配送路线<small style={{marginLeft: 10}}>Delivery route</small></h3>}>
                            <ScreenLineAdvance />
                        </Card>
                        <Card
                            className={styles.card}
                            headerStyle={{marginTop: 0}}
                            bodyStyle={{paddingTop: 0, paddingLeft: 0, paddingBottom: 0}}
                            title={<h3 className={styles.title}>AGV状态<small style={{marginLeft: 10}}>AGV Status</small></h3>}>
                            <ScreenAgvStatus />
                        </Card>
                        <Card
                            className={styles.card}
                            headerStyle={{marginTop: 0}}
                            bodyStyle={{paddingTop: 0, paddingLeft: 0, paddingBottom: 0}}
                            title={<h3 className={styles.title}>AGV运行效率<small style={{marginLeft: 10}}>AGV OEE</small></h3>}>
                            <ScreenAgvOee />
                        </Card>
                    </Col>
                </Row>
            </Content>
            <Footer className={styles.footer}>
                送料管理系统(版本号:v{packageJson?.version})
            </Footer>
        </Layout>

    </FullScreenContainer>)
}

export default Screen