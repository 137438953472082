import React, {forwardRef, useLayoutEffect } from "react"
import useAutoResize from "@/utils/useAutoResize"
import cs from 'classnames'
import styles from './index.module.less'

interface FullScreenContainerProps {
    children: any
    className?: string
    style?: any
}


const FullScreenContainer = forwardRef(({ children, className, style }: FullScreenContainerProps, ref) => {
    const { domRef } = useAutoResize(ref)

    useLayoutEffect(() => {
        const { clientWidth , clientHeight } = document.body;//document.documentElement;//window.screen

        Object.assign(domRef.current.style, {
            width: `${clientWidth}px`,
            height: `${clientHeight}px`
        })

        // domRef.current.style.transform = `scale(${document.body.clientWidth / width})`
    })

    return (
        <div
            id='dv-full-screen-container'
            className={cs(className, styles.fullScreenContainer)}
            style={style}
            ref={domRef}
        >
            {children}
        </div>
    )
})

export default FullScreenContainer