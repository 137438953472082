import React, {Fragment} from 'react';
import { Chart, LineAdvance} from 'bizcharts';
import { useRequest } from 'ahooks';
import {fetchAgvOee} from "@/pages/screen/service";

const ScreenAgvOee = () =>{
    const {data} = useRequest(()=>{
        return fetchAgvOee();
    }, {
        pollingInterval: 30000,
    })

    return <Fragment>
        <Chart padding={[10, 20, 50, 40]} autoFit height={330} data={data} >
            <LineAdvance
                shape="line"
                point
                area
                position="delivery_at*total"
                color="delivery_by"
            />
        </Chart>
    </Fragment>
}

export default ScreenAgvOee