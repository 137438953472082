export enum StorageKey {
  /* 后台管理通用配置 */
  WEB_ADMIN_CONFIG_KEY = 'WEB_ADMIN_CONFIG_KEY',
  /** 账户信息 */
  IDENTIFY_KEY = 'IDENTIFY',
  /** 是否已经登录 */
  LOGGED_KEY = 'IS_LOGGED',
  /** 角色 */
  ROLE_KEY ='ROLE',
  /** API 环境 */
  API_ENV_CODE_KEY ='DEBUG_API_ENV_CODE',
  /* 会员资料 */
  USER_KEY = 'USER',
  /* 当前位置 */
  LOCATION_KEY = 'LOCATION_KEY',
}
