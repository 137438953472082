import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import defaultSettings from "@/settings.json";
import { StorageKey } from '@/constants';

interface appProps {
    theme: string;
    lang: string;
    setLang: (lang) => void;
    setTheme: (theme: string) => void;
    settings: typeof defaultSettings;
    setSettings: (settings: typeof defaultSettings) => void;
}

const useAppStore = create(
    persist<appProps>(
        (set) => ({
            lang: 'zh-CN',//todo
            setLang: (lang) => set(() => ({lang})),
            theme: '',//todo
            setTheme: (theme) => set(()=> ({ theme })),
            settings: defaultSettings,
            setSettings: (settings) => set(() => ({ settings })),
        }),
        { name: StorageKey.WEB_ADMIN_CONFIG_KEY }
    )
)

export default useAppStore