import React from 'react';
import AuthLayout from "@/layouts/auth-layout";
import {Navigate, Route, Routes } from "react-router-dom";
import LoginForm from "./auth/components/login-form";
import RegisterForm from './auth/components/register-form';
import ForgotPasswordForm from './auth/components/forgot-password-form';

const AuthPage = () => (
    <Routes>
        <Route element={<AuthLayout />}>
            <Route path="login" element={<LoginForm />} />
            <Route path="register" element={<RegisterForm />} />
            <Route path="forgot-password" element={<ForgotPasswordForm />} />
            <Route index element={<Navigate to="login" />} />
        </Route>
    </Routes>
)
export default AuthPage