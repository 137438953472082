import { request } from "@/utils/request"
import {DeliveryProps} from "./model"

export const fetchScreenDeliveries = async (
    options?: any,
): Promise<DeliveryProps[]> => {
    return await request.get('/v1/screen/delivery', {
        params: {
            ...options,
        },
    })
}

export const fetchDailyDelivery = async (
    options?: any,
): Promise<any> => {
    return await request.get('/v1/screen/daily-delivery', {
        params: {
            ...options,
        },
    })
}

export const fetchSevenDailyDelivery = async (): Promise<any> => {
    return await request.get('/v1/screen/seven-delivery')
}

export const fetchAgvStatus = async (formParams): Promise<any> => {
    return await  request.get('/v1/screen/agv-status', {
        params: {
            ...formParams
        }
    })
}

export const fetchAgvOee = async (formParams?): Promise<any> => {
    return await  request.get('/v1/screen/agv-oee',{
        params: {
            ...formParams
        }
    })
}

export const fetchScreenContract = async (): Promise<any> => {
    return await  request.get('/v1/screen/contract')
}